import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { Layout } from '../components'
import { randomLinkColour, resetLinkColour } from '../functions/randomColour'

const rotate = keyframes`
  from {
    transform: scaleX(-1) scaleY(1) rotate(0deg);
  }

  to {
    transform: scaleX(-1) scaleY(1) rotate(-360deg);
  }
`

const Intro = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  margin: auto 0;
  p {
    font-family: 'IBM Plex Mono', monospace;
    font-size: 1rem;
    color: #444;
  }
`

const Body = styled.div`
  height: 100%;
`

const LinkWrapper = styled.div`
  transition: all 0.25s;
  a {
    text-decoration: none;
  }
`

const LinkText = styled.h3`
  font-family: 'bluu';
  font-size: 5rem;
  margin: 1rem 0;
  line-height: 115%;
  position: relative;
  color: rgb(55, 55, 55);
  &::before {
    content: 'A';
    font-family: 'mrpx';
    position: absolute;
    transform: scaleX(-0.1) scaleY(0.1);
    font-size: 50%;
    left: -1rem;
    color: black;
    opacity: 0;
    top: 10px;
    transition: all 0.3s ease-out;
  }
  &:hover ::before {
    opacity: 1;
    left: -4rem;
    top: 3px;
    transform: scaleX(-1) scaleY(1);
    transition: all 0.3s ease-out;
    animation: ${rotate} 3s linear infinite;
    animation-delay: 1s;
  }
`

const PortfolioLink = styled(LinkText)`
  &:before {
    content: 'J';
  }
`

const AboutLink = styled(LinkText)`
  &:before {
    content: 'A';
  }
`

const ResumeLink = styled(LinkText)`
  &:before {
    content: 'D';
  }
`

const Links = styled.div`
  margin-top: 100px;
`

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query IntroQuery {
        markdownRemark(frontmatter: { path: { eq: "/intro" } }) {
          html
        }
      }
    `}
    render={data => (
      <Layout>
        <Body>
          <Intro>
            <Wrapper
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            ></Wrapper>
          </Intro>
          {/* <Links>
            <LinkWrapper>
              <Link to="/work">
                <PortfolioLink
                  onMouseEnter={randomLinkColour}
                  onMouseLeave={resetLinkColour}
                >
                  Portfolio
                </PortfolioLink>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/about">
                <AboutLink
                  onMouseEnter={randomLinkColour}
                  onMouseLeave={resetLinkColour}
                >
                  Briefly About Myself
                </AboutLink>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/resume">
                <ResumeLink
                  onMouseEnter={randomLinkColour}
                  onMouseLeave={resetLinkColour}
                >
                  Resume
                </ResumeLink>
              </Link>
            </LinkWrapper>
          </Links> */}
        </Body>
      </Layout>
    )}
  />
)

export default IndexPage
